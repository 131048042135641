import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import * as _ from 'lodash';

import './check-box.css';
import useFetch from '../hooks/useFetch';
import { CheckBox } from '../components/check-box/CheckBox';
import { PATHS } from './path.constant';
import { isLoadingStore } from '../components/loading/Loading';
import useEditMovieStore from '../hooks/useEditMovieStore';

export const MovieListPage = () => {
  const [url, setUrl] = useState('https://api.jakestory.me/movie/reservation')
  const [resultUrl, setResultUrl] = useState('https://api.jakestory.me/movie/reservation/results')
  const reservations = useFetch(url);
  const results = useFetch(resultUrl);
  const [selectedReserve, setSelectedReserve] = useState<any[]>([]);
  const [selectedResult, setSelectedResult] = useState<Set<number>>(new Set());
  const { startLoading, stopLoading } = isLoadingStore();
  const { setEditMovie } = useEditMovieStore();
  const navigate = useNavigate();

  const onClickCheck = (reserve: any) => {
    setSelectedReserve(reserves => {
      if (reserves.find(v => v.id === reserve.id)) {
        _.remove(reserves, v => v.id === reserve.id);
      } else {
        reserves.push(reserve);
      }
      return _.clone(reserves);
    });
  }

  const onClickResultCheck = (resultId: any) => {
    setSelectedResult(results => {
      if (results.has(resultId)) {
        results.delete(resultId);
      } else {
        results.add(resultId);
      }
      return new Set(results);
    });
  }

  const onClickEdit = useCallback(() => {
    const reservation = selectedReserve[0];
    setEditMovie(reservation);
    navigate(PATHS.edit);
  }, [selectedReserve, setEditMovie, navigate])

  const onClickDelete = async () => {
    startLoading();
    try {
      const reserves = Array.from(selectedReserve);
      const ids: string[] = reserves.map((reserve: any) => reserve.id);
      for (const id of ids) {
        await axios.delete(`https://api.jakestory.me/movie/reservation/${id}`);
      }
      setUrl('https://api.jakestory.me/movie/reservation?_=' + new Date().getTime());
      setResultUrl('https://api.jakestory.me/movie/reservation/results?_=' + new Date().getTime())
    } finally {
      stopLoading();
    }
  }

  const onClickDeleteResult = async () => {
    startLoading();
    try {
      const resultIds = Array.from(selectedResult);
      for (const id of resultIds) {
        await axios.delete(`https://api.jakestory.me/movie/reservation/result/${id}`);
      }
      setResultUrl('https://api.jakestory.me/movie/reservation/results?_=' + new Date().getTime())
    } finally {
      stopLoading();
    }
  }

  const onClickTest = async () => {
    startLoading();
    try {
      const reserves = Array.from(selectedReserve);
      await axios.post(
        'https://api.jakestory.me/movie/reservation/test',
        reserves[0],
      );
      setResultUrl('https://api.jakestory.me/movie/reservation/results?_=' + new Date().getTime())
    } finally {
      stopLoading();
    }
  }


  const onClickEnable = useCallback(async () => {
    startLoading();
    try {
      const ids: string[] = selectedReserve.map((reserve: any) => reserve.id);
      for (const id of ids) {
        await axios.post(`https://api.jakestory.me/movie/reservation/enable/${id}`);
      }
      setUrl('https://api.jakestory.me/movie/reservation?_=' + new Date().getTime());
    } finally {
      stopLoading();
    }
  }, [selectedReserve, startLoading, stopLoading])


  const onClickDisable = useCallback(async () => {
    startLoading();
    try {
      const ids: string[] = selectedReserve.map((reserve: any) => reserve.id);
      for (const id of ids) {
        await axios.post(`https://api.jakestory.me/movie/reservation/disable/${id}`);
      }
      setUrl('https://api.jakestory.me/movie/reservation?_=' + new Date().getTime());
    } finally {
      stopLoading();
    }
  }, [selectedReserve, startLoading, stopLoading])

  return <section className={'fixed background w-full h-full'}>
    <div className={'w-[1540px] mx-auto'}>
      <header className={'relative h-[148px] mx-[113px] border-white border-b-[3px]'}>
        <img alt={'logo'} src={'/svgs/ccv-logo.svg'} className={'absolute left-[5px] bottom-[30px] w-[134px] h-[60px]'}/>
        <Link
          className={'absolute flex items-center justify-center rounded-[5px] right-[5px] bottom-[25px] w-[200px] h-[46px] bg-white text-[20px]'}
          to={PATHS.reservation}
        >
          예매하러 가기
        </Link>
      </header>
      <div className={'relative h-[500px] px-[133px] pt-[80px] text-white flex justify-between'}>
        <div className={'relative w-[554px] border-white border-[1px] rounded-[10px] p-[24px]'}>
          <p className={'text-white absolute -top-[12px] left-0 -translate-y-full'}>
            예매 진행중
          </p>
          {
            selectedReserve.length === 1 && <button
              className={'text-white absolute -top-[10px] right-[80px] -translate-y-full font-light text-sm'}
              onClick={onClickEdit}
            >
              수정
            </button>
          }
          {
            selectedReserve.length === 1 && <button
              className={'text-white absolute -top-[10px] right-[120px] -translate-y-full font-light text-sm'}
              onClick={onClickTest}
            >
              테스트
            </button>
          }
          <button
            className={'text-white absolute -top-[10px] right-0 -translate-y-full font-light text-sm'}
            onClick={onClickDelete}
          >
            선택 삭제
          </button>
          <button
            className={'text-white absolute -bottom-[10px] right-0 translate-y-full font-light text-sm'}
            onClick={onClickEnable}
          >
            선택 활성화
          </button>
          <button
            className={'text-white absolute -bottom-[10px] right-[100px] translate-y-full font-light text-sm'}
            onClick={onClickDisable}
          >
            선택 비활성화
          </button>
          <div className={'flex w-full items-center justify-between h-[34px] mb-[14px]'}>
            <p className={'movie-style movie-header w-[147px]'}>영화명</p>
            <p className={'movie-style movie-header w-[96px]'}>영화관</p>
            <p className={'movie-style movie-header w-[77px]'}>영화 종류</p>
            <p className={'movie-style movie-header w-[140px]'}>날짜/시간</p>
          </div>
          {reservations?.map((reserve: any, index: number) => {
            const fromTime = reserve.fromTime.replace(/(\d{2})(\d{2})/, '$1:$2');
            const toTime = reserve.toTime.replace(/(\d{2})(\d{2})/, '$1:$2');
            const fromDate = moment(reserve.fromDate, 'YYYYMMDD').format('YY.MM.DD');
            const toDate = moment(reserve.toDate, 'YYYYMMDD').format('YY.MM.DD');
            return <div
              className={`relative flex w-full items-center justify-between hover:border-white hover:border-[1px] rounded-[10px] movie-reservations-list ${reserve.isAvailable ? 'bg-enable' : ''}`}
              onClick={() => onClickCheck(reserve)}
              key={`reserves-${index}`}
            >
              <CheckBox isChecked={selectedReserve.find(v => v.id === reserve.id) != null} />
              <p className={'movie-style w-[147px]'}>{reserve.title}</p>
              <p className={'movie-style w-[96px]'}>{reserve.theaters.join(',')}</p>
              <p className={'movie-style w-[77px]'}>{reserve.type}</p>
              <p className={'movie-style w-[140px]'}>{fromDate}-{toDate} ({fromTime}-{toTime})</p>
            </div>;
          })}
        </div>
        <div className={'relative w-[647px] border-white border-[1px] rounded-[10px] p-[24px] gap-2'}>
          <p className={'text-white absolute -top-[12px] left-0 -translate-y-full'}>
            예매 완료
          </p>
          <button
            className={'text-white absolute -top-[10px] right-0 -translate-y-full font-light text-sm'}
            onClick={onClickDeleteResult}
          >
            선택 삭제
          </button>
          <div className={'flex w-full items-center justify-between h-[34px] mb-[14px]'}>
            <p className={'movie-style movie-header w-[147px]'}>영화명</p>
            <p className={'movie-style movie-header w-[96px]'}>영화관</p>
            <p className={'movie-style movie-header w-[57px]'}>종류</p>
            <p className={'movie-style movie-header w-[100px]'}>날짜/시간</p>
            <p className={'movie-style movie-header w-[120px]'}>예매좌석</p>
          </div>

          {results?.map((result, index) => (
            <div
              className={'relative flex w-full items-center justify-between hover:border-white hover:border-[1px] rounded-[10px] movie-reservations-list'}
              onClick={() => onClickResultCheck(result.id)}
              key={`result-${index}`}
            >
              <CheckBox isChecked={selectedResult.has(result.id)} />
              <p className={'movie-style w-[147px]'}>{result.title.replace(/\(.*?\)/, '')}</p>
              <p className={'movie-style w-[96px]'}>{result.theater}</p>
              <p className={'movie-style w-[57px]'}>{result.type}</p>
              <p className={'movie-style w-[100px]'}>{result.time}</p>
              <p className={'movie-style w-[120px]'}>{result.seatNoList.join(',')}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </section>;
};
