import { FC } from 'react';
import useMovieStore from '../../hooks/useMovieStore';

export const MovieRangeYInput: FC<{ title: string }> = ({title}) => {
  const { fromY, toY, setFromY, setToY } = useMovieStore();

  return <div className={'w-[322px] flex items-center justify-between relative mb-buttons text-sm'}>
    <div className={'flex flex-col items-end'}>
      <p className={'text-white'}>{title}</p>
      <p className={'text-white text-xs'}>앞에서부터</p>
    </div>
    <div className={'w-[202px] flex justify-between gap-1'}>
      <input
        className={'h-[47px] w-[100px] bg-white rounded-[5px] font-light flex items-center px-[16px] text-sm shadow-button placeholder:text-base placeholder:text-sm'}
        value={fromY}
        onChange={(e) => setFromY(e.target.value)}
        placeholder={'입력'}
      />
      <input
        className={'h-[47px] w-[100px] bg-white rounded-[5px] font-light flex items-center px-[16px] text-sm shadow-button placeholder:text-base placeholder:text-sm'}
        value={toY}
        onChange={(e) => setToY(e.target.value)}
        placeholder={'입력'}
      />
    </div>
  </div>
}
