import { FC } from 'react';
import { PatternFormat } from 'react-number-format';
import useEditMovieStore from '../../hooks/useEditMovieStore';

export const EditMovieTimeInput: FC<{ title: string }> = ({title}) => {
  const { fromTime, toTime, setFromTime, setToTime } = useEditMovieStore();

  return <div className={'w-[330px] flex items-center justify-between relative mb-buttons text-sm'}>
    <div className={'flex flex-col items-end'}>
      <p className={'text-white'}>{title}</p>
      <p className={'text-white text-xs'}>(hh:mm)</p>
    </div>
    <div className={'flex gap-2.5'}>
      <PatternFormat
        className={'h-[47px] w-[110px] bg-white rounded-[5px] font-light flex items-center px-[16px] text-sm shadow-button placeholder:text-base placeholder:text-sm'}
        format={'##:##'}
        value={fromTime}
        onChange={(e) => setFromTime(e.target.value)}
        placeholder={'입력'}
      />
      <PatternFormat
        className={'h-[47px] w-[110px] bg-white rounded-[5px] font-light flex items-center px-[16px] text-sm shadow-button placeholder:text-base placeholder:text-sm'}
        format={'##:##'}
        value={toTime}
        onChange={(e) => setToTime(e.target.value)}
        placeholder={'입력'}
      />
    </div>
  </div>
}
