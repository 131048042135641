import { Link, useNavigate } from 'react-router-dom';
import { FC, useCallback } from 'react';
import axios from 'axios';
import * as _ from 'lodash';
import moment from 'moment';

import './reservation.css';
import { MovieSelection } from '../components/movie/MovieSelection';
import { MovieDateSelection } from '../components/movie/MovieDateSelection';
import { MovieTimeInput } from '../components/movie/MovieTimeInput';
import { MovieSeatCountInput } from '../components/movie/MovieSeatCountInput';
import { MovieSelectSeatType } from '../components/movie/MovieSelectSeatType';
import { MovieSelectProgress } from '../components/movie/MovieSelectProgress';
import { MovieRangeXInput } from '../components/movie/MovieRangeXInput';
import { MovieRangeYInput } from '../components/movie/MovieRangeYInput';
import useFetch from '../hooks/useFetch';
import { PATHS } from './path.constant';
import useMovieStore from '../hooks/useMovieStore';
import { isLoadingStore } from '../components/loading/Loading';

export const ReservationPage: FC = () => {
  const movies = useFetch('https://api.jakestory.me/movie');
  const titles = movies?.map((movie: any) => movie.title) ?? [];
  const { title, setTitle, theater, setTheater, type, setType, user, setUser } = useMovieStore();
  const reservation = useMovieStore();
  const { stopLoading, startLoading } = isLoadingStore();
  const navigate = useNavigate();

  const onClickReservationStart = useCallback(async () => {
    startLoading();
    try {
      const { title, type, theater, seatCount, isNonStop, isAnywhere, fromDate, fromTime, fromX, fromY, toDate, toTime, toX, toY, user } = reservation;
      await axios.post(
        'https://api.jakestory.me/movie/reservation',
        {
          title, type,
          theaters: [theater],
          isNonStop, isAnywhere, user,
          seatCount: parseInt(seatCount) ? parseInt(seatCount) : undefined,
          fromDate: moment(fromDate).format('YYYYMMDD'),
          toDate: moment(toDate).format('YYYYMMDD'),
          fromTime,
          toTime,
          fromX: parseInt(fromX) ? parseInt(fromX) : undefined,
          toX: parseInt(toX) ? parseInt(toX) : undefined,
          fromY: _.isEmpty(fromY) ? undefined : fromY,
          toY: _.isEmpty(toY) ? undefined : toY,
        });
    } finally {
      stopLoading();
      reservation.resetMovie();
      navigate(PATHS.main);
    }
  }, [reservation, startLoading, stopLoading, navigate]);

  const onClickTest = useCallback(async () => {
    startLoading();
    try {
      const { title, type, theater, seatCount, isNonStop, isAnywhere, fromDate, fromTime, fromX, fromY, toDate, toTime, toX, toY, user } = reservation;
      await axios.post(
        'https://api.jakestory.me/movie/reservation/test',
        {
          title, type,
          theaters: [theater],
          isNonStop, isAnywhere, user,
          seatCount: parseInt(seatCount) ? parseInt(seatCount) : undefined,
          fromDate: moment(fromDate).format('YYYYMMDD'),
          toDate: moment(toDate).format('YYYYMMDD'),
          fromTime,
          toTime,
          fromX: parseInt(fromX) ? parseInt(fromX) : undefined,
          toX: parseInt(toX) ? parseInt(toX) : undefined,
          fromY: _.isEmpty(fromY) ? undefined : fromY,
          toY: _.isEmpty(toY) ? undefined : toY,
        });
    } finally {
      stopLoading();
    }
  }, [reservation, startLoading, stopLoading]);

  return <section className={'fixed background w-full h-full'}>
    <header className={'relative h-[148px] mx-[113px] border-white border-b-[3px]'}>
      <img alt={'logo'} src={'/svgs/ccv-logo.svg'} className={'absolute left-[5px] bottom-[30px] w-[134px] h-[60px]'}/>
      <button
        className={'absolute flex items-center justify-center rounded-[5px] right-[435px] bottom-[25px] w-[200px] h-[46px] bg-white text-[20px]'}
        onClick={onClickTest}
      >
        테스트
      </button>
      <Link
        className={'absolute flex items-center justify-center rounded-[5px] right-[220px] bottom-[25px] w-[200px] h-[46px] border-white border-[1px] bg-transparent text-[20px] text-white'}
        to={PATHS.main}
      >
        진행상황
      </Link>
      <button
        className={'absolute flex items-center justify-center rounded-[5px] right-[5px] bottom-[25px] w-[200px] h-[46px] bg-white text-[20px]'}
        onClick={onClickReservationStart}
      >
        예매 시작
      </button>
    </header>
    <div className={'relative px-[133px] pt-[80px] text-white flex justify-between'}>
      <div className={'w-[322px]'}>
        <MovieSelection title={'영화명'} options={titles} className={'z-30'} value={title} onChange={setTitle}/>
        <MovieSelection title={'영화관'} options={['용산', '왕십리', '강남', '압구정']} className={'z-20'} value={theater}
                        onChange={setTheater}/>
        <MovieSelection title={'영화관 종류'} options={['IMAX', '4DX', 'SCREENX', 'All']} className={'z-10'}  value={type} onChange={setType}/>
        <MovieSelection title={'예약자'} options={['jake', 'dana']} value={user} onChange={setUser}/>
      </div>
      <div className={'w-[322px]'}>
        <MovieDateSelection title={'시작/종료일'}/>
        <MovieTimeInput title={'시작/종료시간'}/>
      </div>
      <div className={'w-[322px]'}>
        <MovieSeatCountInput title={'좌석 갯수'}/>
        <MovieSelectSeatType title={'좌석 타입'}/>
        <MovieSelectProgress title={'진행방식'}/>
        <MovieRangeXInput title={'좌석 범위(X축)'}/>
        <MovieRangeYInput title={'좌석 범위(Y축)'}/>
      </div>
    </div>
  </section>;
};
