import { FC } from 'react';

export const CheckBox: FC<{ isChecked: boolean, onClick?: () => void }> = ({ isChecked, onClick }) => {

  return <div
    className="absolute left-[6px] flex items-center justify-center w-3.5 h-3.5 rounded-[3px] bg-transparent"
    onClick={onClick}
  >
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="#FFF"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
    >
      {
        isChecked &&
        <path
          d="M0.288412 4.02987C0.101624 3.85538 0.00507267 3.62327 0.00018976 3.38956C-0.00462805 3.15593 0.0822878 2.92014 0.261588 2.73843C0.440759 2.55686 0.679371 2.46287 0.919545 2.45818C1.15985 2.45337 1.40211 2.53767 1.58902 2.71228L3.2028 4.22284L6.41569 0.2526C6.42487 0.24101 6.43515 0.23075 6.44674 0.222707C6.63789 0.0616498 6.87781 -0.0110569 7.11232 0.00135646L7.12377 0.00211646C7.35965 0.0173165 7.59006 0.119283 7.76018 0.303963C7.93323 0.491747 8.01168 0.73026 7.9986 0.96339L7.99782 0.974537C7.98245 1.19988 7.88121 1.42009 7.69775 1.58456L3.84194 6.15027C3.83471 6.1592 3.82683 6.16711 3.81798 6.17383C3.63744 6.32804 3.40944 6.40366 3.18274 6.39986C2.95416 6.396 2.72603 6.31145 2.54855 6.14552L0.288412 4.02987Z"
        ></path>
      }
    </svg>
  </div>;
};
