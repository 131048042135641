import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import useEditMovieStore from '../../hooks/useEditMovieStore';

export const EditMovieSeatCountInput: FC<{ title: string }> = ({ title }) => {
  const { seatCount, setSeatCount } = useEditMovieStore();

  return <div className={'flex items-center justify-between relative mb-buttons'}>
    <p className={'text-white'}>{title}</p>
    <NumericFormat
      className={'flex justify-between h-[47px] w-[202px] text-sm bg-white rounded-[5px] font-light flex items-center px-[16px] shadow-button placeholder:text-base placeholder:text-sm'}
      suffix={'석'}
      placeholder={'좌석 수를 입력해주세요.'}
      value={seatCount}
      onChange={(e) => setSeatCount(e.target.value)}
    />
  </div>
}
