import { FC } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import useMovieStore from '../../hooks/useMovieStore';

export const MovieDateSelection: FC<{ title: string }> = ({title}) => {
  const { fromDate, toDate, setFromDate, setToDate } = useMovieStore();

  return <div className={'w-[330px] flex items-center justify-between relative mb-buttons text-sm'}>
    <p className={'text-white'}>{title}</p>
    <div className={'flex'}>
      <DatePicker
        className={'h-[47px] w-[110px] bg-white rounded-[5px] font-light flex items-center px-[16px] cursor-pointer text-sm shadow-button mr-2.5'}
        selected={fromDate}
        onChange={setFromDate}
        title={'선택'}
        showTimeSelect={true}
        dateFormat={'M월 dd일'}
      />
      <DatePicker
        className={'h-[47px] w-[110px] bg-white rounded-[5px] font-light flex items-center px-[16px] cursor-pointer text-sm shadow-button'}
        selected={toDate}
        onChange={setToDate}
        title={'선택'}
        dateFormat={'M월 dd일'}
      />
    </div>
  </div>
}
