import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import useMovieStore from '../../hooks/useMovieStore';

export const MovieRangeXInput: FC<{ title: string }> = ({title}) => {
  const { fromX, toX, setFromX, setToX } = useMovieStore();

  return <div className={'w-[322px] flex items-center justify-between relative mb-buttons text-sm'}>
    <div className={'flex flex-col items-end'}>
      <p className={'text-white'}>{title}</p>
      <p className={'text-white text-xs'}>왼쪽부터</p>
    </div>
    <div className={'w-[202px] flex justify-between gap-1'}>
      <NumericFormat
        className={'h-[47px] w-[100px] bg-white rounded-[5px] font-light flex items-center px-[16px] text-sm shadow-button placeholder:text-base placeholder:text-sm'}
        value={fromX}
        suffix={'행부터'}
        onChange={(e) => setFromX(e.target.value)}
        placeholder={'입력'}
      />
      <NumericFormat
        className={'h-[47px] w-[100px] bg-white rounded-[5px] font-light flex items-center px-[16px] text-sm shadow-button placeholder:text-base placeholder:text-sm'}
        value={toX}
        suffix={'행까지'}
        onChange={(e) => setToX(e.target.value)}
        placeholder={'입력'}
      />
    </div>
  </div>
}
