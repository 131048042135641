import { FC, useState } from 'react';

interface IProps { title: string, options: string[], className?: string, value?: string, onChange?: (value: string) => void }
export const MovieSelection: FC<IProps> = ({ title, options, className, value, onChange }) => {
  const [isSelected, setIsSelected] = useState(false);

  const onClickOption = (e: any, option: string) => {
    onChange?.(option);
    setIsSelected(false);
    e.stopPropagation();
  };

  return <div className={'flex items-center justify-between relative mb-buttons ' + className}>
    <p className={'text-white'}>{title}</p>
    <button
      className={'relative flex justify-between h-[47px] w-[202px] text-sm bg-white rounded-[5px] font-light flex items-center px-[16px] cursor-pointer shadow-button'}
      onClick={() => setIsSelected(!isSelected)}
      // onBlur={() => setIsSelected(false)}
    >
      <p className={'break-keep'}>{value !== '' ? value : '옵션을 선택해주세요.'}</p>
      <svg
        width="15"
        height="9"
        viewBox="0 0 15 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
      >
        <path
          d="M8.10521 8.60092C7.79584 8.99062 7.20416 8.99062 6.89479 8.60092L1.13363 1.34373C0.731606 0.837317 1.09225 0.0905542 1.73883 0.0905542L13.2612 0.0905542C13.9078 0.0905542 14.2684 0.837317 13.8664 1.34373L8.10521 8.60092Z"
          fill="#EE2F25"
        ></path>
      </svg>
      {
        isSelected &&
        <div
          className={'w-full h-[153px] absolute left-0 bottom-0 bg-white translate-y-[163px] shadow-button rounded-[5px] px-2 py-1 overflow-y-scroll'}>
          {options.map(option =>
            <p
              className="w-full min-h-[39px] flex items-center text-xs font-light text-left text-[#ee2f25] p-1.5 border-base border-b-[1px] break-keep last:border-b-0"
              onClick={e => onClickOption(e, option)}
            >
              {option}
            </p>
          )}
        </div>
      }
    </button>
  </div>;
};
