import { FC } from 'react';
import useEditMovieStore from '../../hooks/useEditMovieStore';

export const EditMovieSelectSeatType: FC<{ title: string }> = ({ title }) => {
  const { isAnywhere, setIsAnywhere } = useEditMovieStore();

  const BUTTON_STYLE = 'h-[47px] w-[100px] text-sm bg-white rounded-[5px] font-light flex items-center justify-center px-[16px] shadow-button';
  const BUTTON_SELECTED = 'h-[47px] w-[100px] text-sm bg-base text-white rounded-[5px] font-light flex items-center justify-center px-[16px] shadow-button border-white border-[1px]';
  return <div className={'flex items-center justify-between relative mb-buttons w-[322px] grid-cols-3'}>
    <p className={'text-white'}>{title}</p>
    <div className={'w-[202px] flex justify-between gap-1'}>
      <button
        className={!isAnywhere ? BUTTON_STYLE : BUTTON_SELECTED}
        onClick={() => setIsAnywhere(false)}
      >
        연석
      </button>
      <button
        className={isAnywhere ? BUTTON_STYLE : BUTTON_SELECTED}
        onClick={() => setIsAnywhere(true)}
      >
        상관 x
      </button>
    </div>
  </div>
}
