import { FC } from 'react';
import ReactLoading from 'react-loading';
import { create } from 'zustand';

interface IIsLoadingStore {
  isLoading: boolean;
  startLoading: () => void;
  stopLoading: () => void;
}

export const isLoadingStore = create<IIsLoadingStore>(set => ({
  isLoading: false,
  startLoading: () => set({ isLoading: true }),
  stopLoading: () => set({ isLoading: false }),
}));

export const Loading: FC = () => {
  const { isLoading } = isLoadingStore();
  if (!isLoading) {
    return null;
  }

  return <div className={'fixed w-full h-full left-0 top-0 z-50 flex justify-center items-center'}>
    <ReactLoading type={'spinningBubbles'} color={'white'} className={'z-50'} width={80} height={80}/>
    <div className={'absolute w-full h-full left-0 top-0 bg-black opacity-25'} />
  </div>
}
