import { useEffect, useState } from 'react';
import { isLoadingStore } from '../components/loading/Loading';

const useFetch = (url: string) => {
  const [data, setData] = useState<any[]>([]);
  const { stopLoading, startLoading } = isLoadingStore();

  useEffect(() => {
    startLoading()
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setData(data);
      })
      .finally(stopLoading)
  }, [url, startLoading, stopLoading]);
  return data;
};

export default useFetch;
