import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import './font.css';
import reportWebVitals from './reportWebVitals';
import { MovieListPage } from './pages/MovieListPage';
import { ReservationPage } from './pages/ReservationPage';
import { PATHS } from './pages/path.constant';
import { Loading } from './components/loading/Loading';
import { EditReservationPage } from './pages/EditReservationPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <Loading />
    <Routes>
      <Route Component={ReservationPage} path={PATHS.reservation} />
      <Route Component={EditReservationPage} path={PATHS.edit} />
      <Route Component={MovieListPage} path={PATHS.main}/>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
