import { create } from 'zustand';

interface IMovieStore {
  title: string;
  theater: string;
  type: string;
  fromDate: Date;
  toDate: Date;
  fromTime: string;
  toTime: string;
  seatCount: string;
  isAnywhere: boolean;
  isNonStop: boolean;
  fromX: string;
  toX: string;
  fromY: string;
  toY: string;
  user: string;
  setTitle: (value: string) => void;
  setTheater: (value: string) => void;
  setType: (value: string) => void;
  setFromDate: (fromDate: Date) => void;
  setToDate: (toDate: Date) => void;
  setFromTime: (fromTime: string) => void;
  setToTime: (toTime: string) => void;
  setSeatCount: (seatCount: string) => void;
  setIsAnywhere: (isAnywhere: boolean) => void;
  setIsNonStop: (isNonStop: boolean) => void;
  setFromX: (fromX: string) => void;
  setToX: (toX: string) => void;
  setFromY: (fromY: string) => void;
  setToY: (toY: string) => void;
  setUser: (user: string) => void;
  resetMovie: () => void;
}

const initialValue = {
  title: '',
  theater: '',
  type: '',
  fromDate: new Date(),
  toDate: new Date(),
  fromTime: '0000',
  toTime: '3000',
  seatCount: '1',
  isAnywhere: false,
  isNonStop: false,
  fromX: '',
  toX: '',
  fromY: '',
  toY: '',
  user: 'jake',
};

const useStore = create<IMovieStore>(set => ({
  ...initialValue,
  setTitle: (value: string) => set({ title: value }),
  setTheater: (value: string) => set({ theater: value }),
  setType: (value: string) => set({ type: value }),
  setFromDate: (fromDate: Date) => set({ fromDate }),
  setToDate: (toDate: Date) => set({ toDate }),
  setFromTime: (fromTime: string) => set({ fromTime }),
  setToTime: (toTime: string) => set({ toTime }),
  setSeatCount: (seatCount: string) => set({ seatCount }),
  setIsAnywhere: (isAnywhere: boolean) => set({ isAnywhere }),
  setIsNonStop: (isNonStop: boolean) => set({ isNonStop }),
  setFromX: (fromX: string) => set({ fromX }),
  setToX: (toX: string) => set({ toX }),
  setFromY: (fromY: string) => set({ fromY }),
  setToY: (toY: string) => set({ toY }),
  setUser: (user: string) => set({ user }),
  resetMovie: () => set(initialValue)
}))

export default useStore
